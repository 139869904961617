import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/userProtocol',
    name: 'userProtocol',
    component: () => import(/* webpackChunkName: "about" */ '../views/userProtocol.vue')
  },
  {
    path: '/privacyPolicy',
    name: 'privacyPolicy',
    component: () => import(/* webpackChunkName: "about" */ '../views/privacyPolicy.vue')
  },
  {
    path: '/elemeguize',
    name: 'eleme',
    component: () => import(/* webpackChunkName: "about" */ '../views/eleme.vue')
  },
  {
    path: '/meituanguize',
    name: 'meituan',
    component: () => import(/* webpackChunkName: "about" */ '../views/meituan.vue')
  },
  {
    path: '/taobaoguize',
    name: 'taobao',
    component: () => import(/* webpackChunkName: "about" */ '../views/taobao.vue')
  },
  {
    path: '/jingdongguize',
    name: 'jingdong',
    component: () => import(/* webpackChunkName: "about" */ '../views/jingdong.vue')
  },
  {
    path: '/pinduoduoguize',
    name: 'pinduoduo',
    component: () => import(/* webpackChunkName: "about" */ '../views/pinduoduo.vue')
  },
  {
    path: '/weipinhuiguize',
    name: 'weipinhui',
    component: () => import(/* webpackChunkName: "about" */ '../views/weipinhui.vue')
  },
  {
    path: '/kaolaguize',
    name: 'kaola',
    component: () => import(/* webpackChunkName: "about" */ '../views/kaola.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/about.vue')
  },
]

const router = new VueRouter({
  routes,
})

export default router
